@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/Roboto-Regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/Roboto-Italic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
       url('../theme/fonts/Roboto-Bold-webfont.woff') format('woff');
}
