@media (min-width: 1024px) {
    .main-app-v1-sticky-not-homepage {
        margin-top: 160px !important;
    }

    .main-app-v1-sticky-no-margin {
        margin-top: 0px;
    }
}

.MuiPickersToolbar-toolbar {
    background-color: #185A58 !important;
}

.MuiPickersClockNumber-clockNumberSelected {
    background-color: #6BC498 !important;
}

.MuiPickersDay-daySelected {
    background-color: #6BC498 !important;
}

.MuiPickersSlideTransition-transitionContainer p {
    font-size: 12px !important;
    font-weight: bold !important;
}
.MuiPickersSlideTransition-transitionContainer .MuiPickersDay-day p {
    font-size: 9px !important;
    /* color: blue; */
    font-weight: bold !important;
}

main {
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
